import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './containers/auth/auth-guard/auth-guard';
import { MainLayoutComponent } from './shared/layout/main-layout/main-layout.component';


const routes: Routes = [
  {
    path:'',
    loadChildren:() => import('./containers/auth/auth.module').then(m => m.AuthModule)
  },
 
  {
    path:'',
    component:MainLayoutComponent,
    canActivate:[AuthGuard],
    children:[
      {
        path:'dashboard',
        loadChildren: () => import('./containers/dashboard/dashboard.module').then(m => m.DashboardModule),
      },
      {
        path:'customer',
        loadChildren: () => import('./containers/customers/customers.module').then(m => m.CustomersModule),
      },
      {
        path:'formlist',
        loadChildren: () => import('./containers/formlist/formlist.module').then(m => m.FormlistModule),
      },
      {
        path:'user',
        loadChildren: () => import('./containers/users/users.module').then(m => m.UsersModule),
      },
      {
        path:'audit',
        loadChildren: () => import('./containers/audit/audit.module').then(m => m.AuditModule),
      },
      {
        path:'groups',
        loadChildren: () => import('./containers/form-group/form-group.module').then(m => m.FormGroupModule),
      },
      {
        path:'dropdown',
        loadChildren: () => import('./containers/dropdown/dropdown.module').then(m => m.DropdownModule),
      },
      {
        path:'caqh',
        loadChildren: () => import('./containers/dataupload/dataupload.module').then(m => m.DatauploadModule),
      },
      {
        path:'access_privilege',
        loadChildren: () => import('./containers/access-privilege/access-privilege.module').then(m => m.AccessPrivilegeModule),
      },
      {
        path:'reports',
        loadChildren: () => import('./containers/report/report.module').then(m => m.ReportModule),
      },
      {
        path:'dataimport',
        loadChildren: () => import('./containers/dataimport/dataimport.module').then(m => m.DataimportModule),
      },
      {
        path:'data',
        loadChildren: () => import('./containers/data-management/data-management.module').then(m => m.DataManagementModule),
      },
      {
        path:'caqhview',
        loadChildren: () => import('./containers/caqh/caqh.module').then(m => m.CaqhModule),
      }
      
    ]
  },
  
  {
    path:'**',
    redirectTo:'',
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
