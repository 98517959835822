
<app-header></app-header>
<section id="wrapper">
  <app-side-nav></app-side-nav>
  <div id="content-wrapper">
    <router-outlet></router-outlet>
  </div>
</section>


